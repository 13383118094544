import { AxiosRequestConfig, BaseResponse } from "@/services/types";
import { $api, configAppJson } from "@/services/constant";
import { HOST_API_V1 } from "@/services/env-init";
import {
  FeatureCatPembahasan,
  FeatureCatResult,
  PayloadSubmitFeatureCat,
  ResponseGetPassingGrade,
} from "./types";

const BASE_URL_FEATURE_CAT = HOST_API_V1 + "feature/cat";

export const getFeatureCatQuestion = async (
  type: string,
  config?: AxiosRequestConfig
) => {
  const res = await $api().get(
    `${BASE_URL_FEATURE_CAT}/question/${type}`,
    config
  );
  return res.data;
};

export const postFeatureCatSubmit = async (
  kodeExam: string,
  payload: PayloadSubmitFeatureCat
) => {
  const config = configAppJson();

  const res = await $api().post(
    `${BASE_URL_FEATURE_CAT}/submit/${kodeExam}`,
    payload,
    config
  );

  return res.data;
};

export const getFeatureCatResult = async (
  kodeExam: string
): Promise<BaseResponse<FeatureCatResult>> => {
  const res = await $api().get(`${BASE_URL_FEATURE_CAT}/result/${kodeExam}`);
  return res.data;
};

export const getFeatureCatPembahasan = async (
  kodeExam: string
): Promise<BaseResponse<FeatureCatPembahasan>> => {
  const res = await $api().get(
    `${BASE_URL_FEATURE_CAT}/pembahasan/${kodeExam}`
  );
  return res.data;
};

export const getFeatureJenisCat = async (
  type: string
): Promise<BaseResponse<any>> => {
  const res = await $api().get(`${BASE_URL_FEATURE_CAT}/jenis/${type}`);
  return res.data;
};

export const getFeatureCatPassingGrade = async (
  type: string
): Promise<BaseResponse<ResponseGetPassingGrade>> => {
  const res = await $api().get(`${BASE_URL_FEATURE_CAT}/passing-grade/${type}`);
  return res.data;
};

export const getFeatureCatHistory = async (
  config: AxiosRequestConfig
): Promise<BaseResponse<any>> => {
  const res = await $api().get(`${BASE_URL_FEATURE_CAT}/history`, config);
  return res.data;
};
