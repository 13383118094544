
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { resultExam, thDataUser, thKeterangan } from "./tryout-hasil";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";
import {
  CatPembahasan,
  CatResult,
  MemberCatQuestion,
} from "@/services/v1/member/cat/types";
import { isMember } from "@/utils/roles";
import {
  getMemberCatPembahasan,
  getMemberCatResult,
} from "@/services/v1/member/cat";
import {
  getFeatureCatPembahasan,
  getFeatureCatResult,
} from "@/services/v1/feature/cat";
import { Breadcrumb, dashboardPath } from "@/components/top-bar";
import { setBreadcrumb } from "@/utils/breadcrumb";

export default defineComponent({
  setup() {
    // START === setup breadcrumb =====================================================
    const breadcrumb: Breadcrumb[] = [
      {
        title: "Dashboard",
        to: dashboardPath,
      },
      {
        title: "Hasil CAT",
        disabled: true,
      },
    ];

    onMounted(() => {
      setBreadcrumb(breadcrumb);
    });
    // END === setup breadcrumb =======================================================

    const route = useRoute();
    const params = route.params;
    const resp = resultExam.response;
    const soalList = resultExam.response.soal;
    const config = api.configGet();
    const catCode = route.query?.catCode || "";
    const jwbCat: any = JSON.parse(gpf.memberHasilCat(catCode as string));

    const rn = (): void => {
      cash("#cvRn").toggleClass("transform rotate-180");
      cash("#nsRn").toggleClass("flex-wrap justify-center");
    };

    const rnTop = (id1: string, id2: string): void => {
      cash("#" + id1).toggleClass("transform rotate-180");
      cash("#" + id2).toggleClass("hidden");
    };

    // START --- state page --------------------------------------------------------------------
    const resultCat = ref({} as CatResult);
    const currentSoal = ref({} as CatPembahasan);
    const allSoal = ref([] as CatPembahasan[]);
    const currentSoalIdx = ref(0 as number);
    // END --- state page ----------------------------------------------------------------------

    // START --- for handling pembahasan soal --------------------------------------------------
    const changeSoal = (soal: any, idx: number): void => {
      currentSoal.value = soal;
      currentSoalIdx.value = idx;
    };

    const changeSoalCis = (tp: string): void => {
      tp === "next" ? (currentSoalIdx.value += 1) : (currentSoalIdx.value -= 1);
      currentSoal.value = allSoal.value[currentSoalIdx.value];
    };

    const chooseOpt = (cs: any, no: any, idx: any): void => {
      const a = cs.option;
      for (let i = 0; i < a.length; i++) {
        if (i !== idx) {
          cash('[opt="chs-' + no + "-" + i + '"]').removeClass("active");
        } else {
          cash('[opt="chs-' + no + "-" + idx + '"]').addClass("active");
          allSoal.value[currentSoalIdx.value].answer = cs.option[idx].option;
          allSoal.value[currentSoalIdx.value].answerIdx = idx;
        }
      }
    };
    // START --- for handling pembahasan soal --------------------------------------------------

    // START --- get data result cat -----------------------------------------------------------
    const memberHasilCat = async () => {
      gpf.gLoading.show();

      try {
        let res: any = {};

        if (isMember.value) {
          res = await getMemberCatResult(catCode as string);
        } else {
          res = await getFeatureCatResult(catCode as string);
        }

        const resData = res?.response as CatResult;
        console.log("res memberHasilCat ", resData);

        resultCat.value = resData;

        // call pembahasan cat data
        memberPembahasanCat();
      } catch (err: any) {
        console.error("err member hasil cat ", err?.response);
      } finally {
        gpf.gLoading.hide();
      }
    };

    const memberPembahasanCat = async () => {
      gpf.gLoading.show();

      try {
        let res: any = {};

        if (isMember.value) {
          res = await getMemberCatPembahasan(catCode as string);
        } else {
          res = await getFeatureCatPembahasan(catCode as string);
        }

        const resData = res?.response as CatPembahasan[];
        console.log("res memberHasilCat ", resData);

        allSoal.value = resData;
        currentSoal.value = resData[currentSoalIdx.value];
      } catch (err: any) {
        console.error("err memberPembahasanCat ", err?.response);
      } finally {
        gpf.gLoading.hide();
      }
    };

    onMounted(() => {
      memberHasilCat();
    });
    // START --- get data result cat -----------------------------------------------------------

    return {
      params,
      rn,
      resp,
      soalList,
      rnTop,
      jwbCat,
      config,
      gpf,

      resultCat,
      currentSoal,
      allSoal,
      currentSoalIdx,

      changeSoal,
      changeSoalCis,
      chooseOpt,
    };
  },
});
